
import { defineComponent, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import { formatLocalTime } from '@/utils/format-time';

import {
  useUsers,
  useDeleteUser
} from '@/composables/api';
import { DELETED_SUCCESSFULLY_TEXT, IntBoolean, ResponseError, SortOrder, UserIdOption, UserSortKey } from '@/apis';
import { VerifiedStatus } from '@/interfaces';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { setVerifiedStatus } from './utils';

const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.SELECTOR,
    label: '年齡驗證狀態',
    placeholder: '請選擇',
    options: [
      {
        label: '已驗證',
        value: `${VerifiedStatus.VERIFIED}`
      },
      {
        label: '驗證中',
        value: `${VerifiedStatus.VERIFYING}`
      },
      {
        label: '尚未驗證',
        value: `${VerifiedStatus.NOT_VERIFIED}`
      },
      {
        label: '驗證失敗',
        value: `${VerifiedStatus.FAILED}`
      }
    ]
  }
];

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    // query items
    const page = ref(1);
    const pageSize = ref(15);
    const keyword = ref('');
    const isNFTMember = ref();
    const verifiedStatus = ref();
    const registerDateRange = ref(['']);
    const registerStartAt = ref('');
    const registerEndAt = ref('');
    const sortOrder = ref<SortOrder>();
    const sortKey = ref<UserSortKey>();

    const statusIndex = ref(undefined);
    const defaultTime = ref([new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]);

    const { data, isLoading, isFetching, refetch } = useUsers({ page, keyword, isNFTMember, verifiedStatus, registerStartAt, registerEndAt, pageSize, sort: sortOrder, sortKey });
    const { isLoading: isDeleting, mutate } = useDeleteUser();

    const sortChange = ({ prop, order }) => {
      sortKey.value = prop;
      order === 'ascending'
        ? sortOrder.value = SortOrder.ASCENDING
        : sortOrder.value = SortOrder.DESCENDING;

      if (!prop) {
        sortOrder.value = null;
      }
    };

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      isNFTMember.value = event[0];
      verifiedStatus.value = event[1];
      searchKeyword(keyword.value);
    };

    const handleDateChange = () => {
      if (!registerDateRange.value) {
        registerStartAt.value = '';
        registerEndAt.value = '';

        return;
      }
      registerStartAt.value = registerDateRange.value[0];
      registerEndAt.value = registerDateRange.value[1];

      searchKeyword(keyword.value);
    };

    const deleteUser = ({ userId }: UserIdOption, index: Number) => {
      statusIndex.value = index;
      ElMessageBox.confirm('是否確認要刪除？', '警告', {
        confirmButtonText: '刪除',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          mutate(
            { userId },
            {
              onSuccess() {
                refetch.value();

                ElMessage.success(DELETED_SUCCESSFULLY_TEXT);
              },
              onError(error: ResponseError) {
                ElMessage({
                  message: error.response?.data.error.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    return {
      page,
      data,
      isLoading,
      isDeleting,
      isFetching,
      statusIndex,
      defaultTime,
      FILTER_OPTIONS,
      registerDateRange,
      deleteUser,
      sortChange,
      searchKeyword,
      formatLocalTime,
      handleDateChange,
      handleFilterChange,
      setVerifiedStatus
    };
  }
});
